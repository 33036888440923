/* src/styles/Header.css */
/* General header styling */
.header-image {
    background-image: url('../assets/images/header.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; /* Ensures the image covers the entire width */
    width: 100%; /* Full width of the screen */
    height: 80px; /* Set a default height */
    position: relative; /* Allows you to position elements inside the header */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

/* Media queries for responsiveness */

/* For slightly small devices (like small phones) */
@media (max-width: 400px) {
  .header-image {
    height: 20px; /* Reduce the height for smaller screens */
    background-size: contain; /* Ensure the image adjusts within the screen width */
    /*margin-bottom: 0; /* Ensure consistent spacing */
    margin: 0; /* Remove default margin */
  }
}

/* For small devices (like phones) */
@media (max-width: 600px) {
  .header-image {
    height: 32px; /* Reduce the height for smaller screens */
    background-size: contain; /* Ensure the image adjusts within the screen width */
    /*margin-bottom: 0; /* Ensure consistent spacing */
    margin: 0; /* Remove default margin */
  }
}

/* For medium devices (like tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .header-image {
    height: 80px; /* Adjust the height slightly for tablets */
    background-size: cover; /* Ensure the image covers the entire width */
    /*margin-bottom: 0; /* Ensure consistent spacing */
    margin: 0; /* Remove default margin */
  }
}

/* For large devices (desktops and larger screens) */
@media (min-width: 1025px) {
  .header-image {
    height: 100px; /* Adjust the height for larger screens */
    background-size: cover; /* Ensure the image covers the entire width */
  }
}
