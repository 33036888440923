/* Overall container for the page */
.quiz-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
}

/* Section with the second background image and cards */
.background-bottom {
    background-image: url('../assets/images/image_3.png');
    background-size: cover;
    background-position: center;
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column; /* Stack cards in one column by default */
    justify-content: center;
    align-items: center;
}

/* Card styling */
.quiz-card {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 20px;
    width: 100%; /* Full width on mobile */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: black;
    margin-bottom: 20px; /* Space between stacked cards */
    word-wrap: break-word; /* Ensure long text wraps correctly */
    white-space: normal; /* Allow text to wrap inside the card */
}

/* Input field styling for quiz answers */
.quiz-card input[type="text"] {
    width: 100%; /* Full width input field */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 18px;
}

/* Container for the question */
.question-container {
    margin-bottom: 20px; /* Adds space below the question */
  }
  
  /* Options styling */
  .options p {
    margin-bottom: 10px; /* Adds space between each option */
  }
  
/* Button styling */
.quiz-card button {
    margin-top: 20px;
    align-self: flex-start; /* Align the button to the left */
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    text-align: center;
}

.quiz-card button:hover {
    background-color: #45a049;
}

/* On larger screens, display cards in two columns */
@media (min-width: 768px) {
    .background-bottom {
        flex-direction: row; /* Switch to row for two columns */
    }

    .quiz-card {
        width: 45%; /* Each card takes up 45% of the screen width */
        margin-bottom: 0; /* Remove margin between cards when in two columns */
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Align items to the start (left) */
    }
}

/* Styling the quiz titles and sub-titles */
.quiz-card h2 {
    font-size: 32px;
    margin-bottom: 5px;
    text-align: left; /* Align title to the left */
}

.quiz-card h3 {
    font-size: 24px;
    margin-bottom: 5px;
    text-align: left; /* Align subtitle to the left */
}

.quiz-card p {
    font-size: 18px;
    text-align: left; /* Align paragraph text to the left */
    margin-top: 0;
}
