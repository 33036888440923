/* Overall container for the page */
.results-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: space-between;
  }
  
  /* Section with the first background image */
  .background-top {
    background-image: url('../assets/images/header.png');
    background-size: contain;  /* Ensure the whole image fits within the section */
    background-position: center;
    background-repeat: no-repeat;
    height: 150px; /* Adjusted height for responsiveness */
    width: 100%;
    margin: 0;
  }
  
  /* Section with the second background image and cards */
  .background-bottom {
    background-image: url('../assets/images/image_3.png');
    background-size: cover;
    background-position: center;
    flex-grow: 1;
    padding: 40px;
    display: flex;
    flex-direction: column; /* Stack cards in one column by default */
    justify-content: center;
    align-items: flex-start;
  }
  
  /* Card styling */
  .results-card {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 20px;
    width: 100%; /* Full width on mobile */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: black;
    margin-bottom: 20px; /* Space between stacked cards */
  }
  
  /* On larger screens, display cards in two columns */
  @media (min-width: 768px) {
    .background-bottom {
      flex-direction: row; /* Switch to row for two columns */
      justify-content: center; /* Adds space between the two cards */
      gap: 30px; /* Adds space between the cards, customize this value as needed */
    }
    
    .results-card {
      width: 45%; /* Each card takes up 45% of the screen width */
      margin-bottom: 0; /* Remove margin between cards when in two columns */
    }

    /* Achievements section */
  .results-card h1 {
    font-size: 28px;
    margin-bottom: 5px;
    text-align: flex-start;
  }
  
  .results-card h2 {
    font-size: 18px;
    margin-bottom: 5px;
    text-align: center;
  }
  }

  /* On smaller screens, reduce the font size */
  @media (min-width: 600px) {
    .background-bottom {
      flex-direction: row; /* Switch to row for two columns */
      justify-content: center; /* Adds space between the two cards */
    }
    
    .results-card {
      width: 45%; /* Each card takes up 45% of the screen width */
      margin-bottom: 0; /* Remove margin between cards when in two columns */
    }

    /* Achievements section */
  .results-card h1 {
    font-size: 28px;
    margin-bottom: 5px;
    text-align: flex-start;
  }
  
  .results-card h2 {
    font-size: 18px;
    margin-bottom: 5px;
    text-align: center;
  }
  }

  /* Achievements section */
  .results-card h1 {
    font-size: 32px;
    margin-bottom: 5px;
    text-align: flex-start;
  }
  
  .results-card h2 {
    font-size: 24px;
    margin-bottom: 5px;
    text-align: flex-start;
  }
  

  .exit-button-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .exit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .exit-button:hover {
    background-color: #4CAF50;
  }
  