/* Ensure that the homepage fills the entire viewport */
.homepage {
  background-color: #f3f4f6;  /* Fallback background color if images don't load */
  min-height: 100vh; /* Ensure page height always covers full screen */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0; /* Ensure there's no margin on the homepage */
  padding: 0; /* Ensure no padding on the homepage */
}

/* Global reset for margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Section for the sub-header background image */
.sub-header-image {
  background-image: url('../assets/images/sub_header.png');
  background-size: cover; /* Ensures the image covers the entire width */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%; /* Full width of the screen */
  height: 120px; /* Adjust this as per the actual height of your image */
  margin: 0; /* Ensure no margin between header and sub-header */
  padding: 0;
}

/* Section for the third background image */
.third-background {
  background-image: url('../assets/images/second_image.png');
  background-size: cover; /* Ensures the image covers the entire width of the screen */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents image repetition */
  width: 100%; /* Full width of the container */
  height: 100vh; /* Optional: Full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Card style to house the input boxes and the search button */
.card {
  background-color: rgba(0, 0, 0, 0.5); /* Transparent background for the card */
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to make it pop */
  max-width: 400px; /* Limit the card width */
  width: 100%;
  text-align: center;
}

/* Styling for input fields and button */
input, button {
  padding: 12px;
  margin: 15px 0;
  width: 100%;
  font-size: 1.2rem;
  border-radius: 30px; /* Oval shapes for inputs and buttons */
  text-align: center;
  border: none;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .sub-header-image {
    margin-top: 0;
    padding-top: 0;
  }
  .third-background {
    margin-top: 0;
    padding-top: 0;
  }
  .card {
      padding: 30px;
  }
}

@media (max-width: 480px) {
  .sub-header-image {
    margin-top: 0;
    padding-top: 0;
  }
  .third-background {
    margin-top: 0;
    padding-top: 0;
  }
  .card {
      padding: 20px;
  }
}
