/* Overall container for the page */
.results-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: space-between;
  }
  
  /* Section with the first background image */
  .background-top {
    background-image: url('../assets/images/header.png');
    background-size: contain;  /* Ensure the whole image fits within the section */
    background-position: center;
    background-repeat: no-repeat;
    height: 150px; /* Adjusted height for responsiveness */
    width: 100%;
    margin: 0;
  }
  
  /* Section with the second background image and cards */
  .background-bottom {
    background-image: url('../assets/images/image_3.png');
    background-size: cover;
    background-position: center;
    flex-grow: 1;
    padding: 40px;
    display: flex;
    flex-direction: column; /* Stack cards in one column by default */
    justify-content: space-between;
    align-items: flex-start;
  }
  
  /* Card styling */
  .results-card {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 20px;
    width: 100%; /* Full width on mobile */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: black;
    margin-bottom: 20px; /* Space between stacked cards */
  }
  
  /* On larger screens, display cards in two columns */
  @media (min-width: 768px) {
    .background-bottom {
      flex-direction: row; /* Switch to row for two columns */
      justify-content: space-between; /* Adds space between the two cards */
      gap: 30px; /* Adds space between the cards, customize this value as needed */
    }
    
    .king-card {
      width: 45%; /* Each card takes up 45% of the screen width */
      margin-bottom: 0; /* Remove margin between cards when in two columns */
    }
  }

  /* Center the image in the king details card */
  .king-details img {
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    width: 50%;
  }
  
  /* Styling the king's name and reign period */
  .king-details h2 {
    font-size: 50px;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .king-details p {
    font-size: 24px;
    text-align: center;
    margin-top: 0;
  }
  
  /* King Achievements Section */
  .king-achievements {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Achievements section */
  .king-achievements h3 {
    font-size: 32px;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .king-achievements h4 {
    font-size: 28px;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .king-achievements p {
    font-size: 18px;
    text-align: center;
    margin-top: 5px;
  }
  
  /* Navigation buttons with space between them */
  .question-navigation {
    display: flex;
    justify-content: space-between;
    gap: 50px; /* Space between buttons */
    width: 100%;
    margin-top: 20px;
  }
  
  .arrow-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .arrow-button:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
  }
  
  .quiz-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .quiz-button:hover {
    background-color: #45a049;
  }
  